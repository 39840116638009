*, ::before, ::after {
          --tw-border-spacing-x: 0;
          --tw-border-spacing-y: 0;
          --tw-translate-x: 0;
          --tw-translate-y: 0;
          --tw-rotate: 0;
          --tw-skew-x: 0;
          --tw-skew-y: 0;
          --tw-scale-x: 1;
          --tw-scale-y: 1;
          --tw-pan-x:  ;
          --tw-pan-y:  ;
          --tw-pinch-zoom:  ;
          --tw-scroll-snap-strictness: proximity;
          --tw-gradient-from-position:  ;
          --tw-gradient-via-position:  ;
          --tw-gradient-to-position:  ;
          --tw-ordinal:  ;
          --tw-slashed-zero:  ;
          --tw-numeric-figure:  ;
          --tw-numeric-spacing:  ;
          --tw-numeric-fraction:  ;
          --tw-ring-inset:  ;
          --tw-ring-offset-width: 0px;
          --tw-ring-offset-color: #fff;
          --tw-ring-color: rgba(59, 130, 246, 0.5);
          --tw-ring-offset-shadow: 0 0 rgba(0,0,0,0);
          --tw-ring-shadow: 0 0 rgba(0,0,0,0);
          --tw-shadow: 0 0 rgba(0,0,0,0);
          --tw-shadow-colored: 0 0 rgba(0,0,0,0);
          --tw-blur:  ;
          --tw-brightness:  ;
          --tw-contrast:  ;
          --tw-grayscale:  ;
          --tw-hue-rotate:  ;
          --tw-invert:  ;
          --tw-saturate:  ;
          --tw-sepia:  ;
          --tw-drop-shadow:  ;
          --tw-backdrop-blur:  ;
          --tw-backdrop-brightness:  ;
          --tw-backdrop-contrast:  ;
          --tw-backdrop-grayscale:  ;
          --tw-backdrop-hue-rotate:  ;
          --tw-backdrop-invert:  ;
          --tw-backdrop-opacity:  ;
          --tw-backdrop-saturate:  ;
          --tw-backdrop-sepia:  ;
}
::backdrop {
          --tw-border-spacing-x: 0;
          --tw-border-spacing-y: 0;
          --tw-translate-x: 0;
          --tw-translate-y: 0;
          --tw-rotate: 0;
          --tw-skew-x: 0;
          --tw-skew-y: 0;
          --tw-scale-x: 1;
          --tw-scale-y: 1;
          --tw-pan-x:  ;
          --tw-pan-y:  ;
          --tw-pinch-zoom:  ;
          --tw-scroll-snap-strictness: proximity;
          --tw-gradient-from-position:  ;
          --tw-gradient-via-position:  ;
          --tw-gradient-to-position:  ;
          --tw-ordinal:  ;
          --tw-slashed-zero:  ;
          --tw-numeric-figure:  ;
          --tw-numeric-spacing:  ;
          --tw-numeric-fraction:  ;
          --tw-ring-inset:  ;
          --tw-ring-offset-width: 0px;
          --tw-ring-offset-color: #fff;
          --tw-ring-color: rgba(59, 130, 246, 0.5);
          --tw-ring-offset-shadow: 0 0 rgba(0,0,0,0);
          --tw-ring-shadow: 0 0 rgba(0,0,0,0);
          --tw-shadow: 0 0 rgba(0,0,0,0);
          --tw-shadow-colored: 0 0 rgba(0,0,0,0);
          --tw-blur:  ;
          --tw-brightness:  ;
          --tw-contrast:  ;
          --tw-grayscale:  ;
          --tw-hue-rotate:  ;
          --tw-invert:  ;
          --tw-saturate:  ;
          --tw-sepia:  ;
          --tw-drop-shadow:  ;
          --tw-backdrop-blur:  ;
          --tw-backdrop-brightness:  ;
          --tw-backdrop-contrast:  ;
          --tw-backdrop-grayscale:  ;
          --tw-backdrop-hue-rotate:  ;
          --tw-backdrop-invert:  ;
          --tw-backdrop-opacity:  ;
          --tw-backdrop-saturate:  ;
          --tw-backdrop-sepia:  ;
}
.\!visible {
          visibility: visible !important;
}
.visible {
          visibility: visible;
}
.invisible {
          visibility: hidden;
}
.static {
          position: static;
}
.fixed {
          position: fixed;
}
.\!absolute {
          position: absolute !important;
}
.absolute {
          position: absolute;
}
.\!relative {
          position: relative !important;
}
.relative {
          position: relative;
}
.sticky {
          position: sticky;
}
.-bottom-5 {
          bottom: -1.25rem;
}
.bottom-0 {
          bottom: 0px;
}
.bottom-16 {
          bottom: 4rem;
}
.bottom-5 {
          bottom: 1.25rem;
}
.bottom-9 {
          bottom: 2.25rem;
}
.bottom-\[20px\] {
          bottom: 20px;
}
.bottom-\[25px\] {
          bottom: 25px;
}
.bottom-\[30px\] {
          bottom: 30px;
}
.bottom-\[40\%\] {
          bottom: 40%;
}
.left-0 {
          left: 0px;
}
.left-8 {
          left: 2rem;
}
.right-0 {
          right: 0px;
}
.right-1 {
          right: 0.25rem;
}
.right-\[20px\] {
          right: 20px;
}
.right-\[4\%\] {
          right: 4%;
}
.top-0 {
          top: 0px;
}
.top-20 {
          top: 5rem;
}
.top-8 {
          top: 2rem;
}
.top-\[5\%\] {
          top: 5%;
}
.top-\[80px\] {
          top: 80px;
}
.-z-10 {
          z-index: -10;
}
.z-10 {
          z-index: 10;
}
.z-20 {
          z-index: 20;
}
.z-30 {
          z-index: 30;
}
.z-40 {
          z-index: 40;
}
.z-50 {
          z-index: 50;
}
.z-\[100\] {
          z-index: 100;
}
.z-\[39\] {
          z-index: 39;
}
.z-\[50\] {
          z-index: 50;
}
.z-\[51\] {
          z-index: 51;
}
.col-span-2 {
          grid-column: span 2 / span 2;
}
.col-span-3 {
          grid-column: span 3 / span 3;
}
.\!m-0 {
          margin: 0px !important;
}
.m-0 {
          margin: 0px;
}
.m-4 {
          margin: 1rem;
}
.m-auto {
          margin: auto;
}
.\!mx-2 {
          margin-left: 0.5rem !important;
          margin-right: 0.5rem !important;
}
.mx-0 {
          margin-left: 0px;
          margin-right: 0px;
}
.mx-1 {
          margin-left: 0.25rem;
          margin-right: 0.25rem;
}
.mx-10 {
          margin-left: 2.5rem;
          margin-right: 2.5rem;
}
.mx-2 {
          margin-left: 0.5rem;
          margin-right: 0.5rem;
}
.mx-3 {
          margin-left: 0.75rem;
          margin-right: 0.75rem;
}
.mx-4 {
          margin-left: 1rem;
          margin-right: 1rem;
}
.mx-\[10px\] {
          margin-left: 10px;
          margin-right: 10px;
}
.mx-\[5\%\] {
          margin-left: 5%;
          margin-right: 5%;
}
.mx-auto {
          margin-left: auto;
          margin-right: auto;
}
.my-10 {
          margin-top: 2.5rem;
          margin-bottom: 2.5rem;
}
.my-2 {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
}
.my-4 {
          margin-top: 1rem;
          margin-bottom: 1rem;
}
.my-5 {
          margin-top: 1.25rem;
          margin-bottom: 1.25rem;
}
.my-7 {
          margin-top: 1.75rem;
          margin-bottom: 1.75rem;
}
.my-8 {
          margin-top: 2rem;
          margin-bottom: 2rem;
}
.my-auto {
          margin-top: auto;
          margin-bottom: auto;
}
.\!mb-0 {
          margin-bottom: 0px !important;
}
.mb-0 {
          margin-bottom: 0px;
}
.mb-1 {
          margin-bottom: 0.25rem;
}
.mb-10 {
          margin-bottom: 2.5rem;
}
.mb-12 {
          margin-bottom: 3rem;
}
.mb-16 {
          margin-bottom: 4rem;
}
.mb-2 {
          margin-bottom: 0.5rem;
}
.mb-24 {
          margin-bottom: 6rem;
}
.mb-3 {
          margin-bottom: 0.75rem;
}
.mb-4 {
          margin-bottom: 1rem;
}
.mb-5 {
          margin-bottom: 1.25rem;
}
.mb-6 {
          margin-bottom: 1.5rem;
}
.mb-8 {
          margin-bottom: 2rem;
}
.mb-\[10px\] {
          margin-bottom: 10px;
}
.ml-1 {
          margin-left: 0.25rem;
}
.ml-2 {
          margin-left: 0.5rem;
}
.ml-auto {
          margin-left: auto;
}
.mr-0 {
          margin-right: 0px;
}
.mr-1 {
          margin-right: 0.25rem;
}
.mr-10 {
          margin-right: 2.5rem;
}
.mr-2 {
          margin-right: 0.5rem;
}
.mr-3 {
          margin-right: 0.75rem;
}
.mr-5 {
          margin-right: 1.25rem;
}
.mt-0 {
          margin-top: 0px;
}
.mt-1 {
          margin-top: 0.25rem;
}
.mt-10 {
          margin-top: 2.5rem;
}
.mt-12 {
          margin-top: 3rem;
}
.mt-2 {
          margin-top: 0.5rem;
}
.mt-3 {
          margin-top: 0.75rem;
}
.mt-36 {
          margin-top: 9rem;
}
.mt-4 {
          margin-top: 1rem;
}
.mt-5 {
          margin-top: 1.25rem;
}
.mt-7 {
          margin-top: 1.75rem;
}
.mt-\[4\%\] {
          margin-top: 4%;
}
.\!line-clamp-1 {
          overflow: hidden !important;
          display: -webkit-box !important;
          -webkit-box-orient: vertical !important;
          -webkit-line-clamp: 1 !important;
}
.line-clamp-2 {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
}
.block {
          display: block;
}
.inline {
          display: inline;
}
.flex {
          display: flex;
}
.grid {
          display: grid;
}
.\!hidden {
          display: none !important;
}
.hidden {
          display: none;
}
.aspect-square {
          aspect-ratio: 1 / 1;
}
.\!h-\[-webkit-fill-available\] {
          height: -webkit-fill-available !important;
}
.\!h-\[55px\] {
          height: 55px !important;
}
.h-0 {
          height: 0px;
}
.h-1 {
          height: 0.25rem;
}
.h-1\/2 {
          height: 50%;
}
.h-10 {
          height: 2.5rem;
}
.h-11 {
          height: 2.75rem;
}
.h-12 {
          height: 3rem;
}
.h-14 {
          height: 3.5rem;
}
.h-16 {
          height: 4rem;
}
.h-2 {
          height: 0.5rem;
}
.h-4 {
          height: 1rem;
}
.h-5 {
          height: 1.25rem;
}
.h-5\/6 {
          height: 83.333333%;
}
.h-52 {
          height: 13rem;
}
.h-6 {
          height: 1.5rem;
}
.h-8 {
          height: 2rem;
}
.h-9 {
          height: 2.25rem;
}
.h-\[120px\] {
          height: 120px;
}
.h-\[121px\] {
          height: 121px;
}
.h-\[14px\] {
          height: 14px;
}
.h-\[1px\] {
          height: 1px;
}
.h-\[200px\] {
          height: 200px;
}
.h-\[20px\] {
          height: 20px;
}
.h-\[25\%\] {
          height: 25%;
}
.h-\[40px\] {
          height: 40px;
}
.h-\[500px\] {
          height: 500px;
}
.h-\[600px\] {
          height: 600px;
}
.h-\[64px\] {
          height: 64px;
}
.h-\[78px\] {
          height: 78px;
}
.h-\[80\%\] {
          height: 80%;
}
.h-\[80px\] {
          height: 80px;
}
.h-auto {
          height: auto;
}
.h-fit {
          height: -moz-fit-content;
          height: fit-content;
}
.h-full {
          height: 100%;
}
.h-max {
          height: -moz-max-content;
          height: max-content;
}
.h-screen {
          height: 100vh;
}
.max-h-52 {
          max-height: 13rem;
}
.max-h-\[168px\] {
          max-height: 168px;
}
.min-h-\[100px\] {
          min-height: 100px;
}
.min-h-\[500px\] {
          min-height: 500px;
}
.min-h-\[600px\] {
          min-height: 600px;
}
.\!w-80 {
          width: 20rem !important;
}
.\!w-full {
          width: 100% !important;
}
.w-0 {
          width: 0px;
}
.w-1\/2 {
          width: 50%;
}
.w-1\/3 {
          width: 33.333333%;
}
.w-1\/4 {
          width: 25%;
}
.w-1\/5 {
          width: 20%;
}
.w-1\/6 {
          width: 16.666667%;
}
.w-11\/12 {
          width: 91.666667%;
}
.w-2 {
          width: 0.5rem;
}
.w-3\/4 {
          width: 75%;
}
.w-4 {
          width: 1rem;
}
.w-4\/5 {
          width: 80%;
}
.w-40 {
          width: 10rem;
}
.w-5\/6 {
          width: 83.333333%;
}
.w-64 {
          width: 16rem;
}
.w-8 {
          width: 2rem;
}
.w-9 {
          width: 2.25rem;
}
.w-\[-webkit-fill-available\] {
          width: -webkit-fill-available;
}
.w-\[100\%\] {
          width: 100%;
}
.w-\[14px\] {
          width: 14px;
}
.w-\[180px\] {
          width: 180px;
}
.w-\[24px\] {
          width: 24px;
}
.w-\[250px\] {
          width: 250px;
}
.w-\[262px\] {
          width: 262px;
}
.w-\[270px\] {
          width: 270px;
}
.w-\[2px\] {
          width: 2px;
}
.w-\[300px\] {
          width: 300px;
}
.w-\[350px\] {
          width: 350px;
}
.w-\[64px\] {
          width: 64px;
}
.w-\[90\%\] {
          width: 90%;
}
.w-auto {
          width: auto;
}
.w-fit {
          width: -moz-fit-content;
          width: fit-content;
}
.w-full {
          width: 100%;
}
.w-screen {
          width: 100vw;
}
.min-w-0 {
          min-width: 0px;
}
.min-w-\[150px\] {
          min-width: 150px;
}
.min-w-min {
          min-width: -moz-min-content;
          min-width: min-content;
}
.max-w-\[150px\] {
          max-width: 150px;
}
.max-w-full {
          max-width: 100%;
}
.flex-1 {
          flex: 1 1 0%;
}
.transform {
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes bounce {
          0%, 100% {
                    transform: translateY(-25%);
                    animation-timing-function: cubic-bezier(0.8,0,1,1);
          }
          50% {
                    transform: none;
                    animation-timing-function: cubic-bezier(0,0,0.2,1);
          }
}
.animate-bounce {
          animation: bounce 1s infinite;
}
.\!cursor-pointer {
          cursor: pointer !important;
}
.cursor-default {
          cursor: default;
}
.cursor-pointer {
          cursor: pointer;
}
.resize {
          resize: both;
}
.grid-cols-1 {
          grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
          grid-template-columns: repeat(2, minmax(0, 1fr));
}
.flex-row {
          flex-direction: row;
}
.flex-col {
          flex-direction: column;
}
.flex-wrap {
          flex-wrap: wrap;
}
.content-center {
          align-content: center;
}
.content-start {
          align-content: flex-start;
}
.items-start {
          align-items: flex-start;
}
.items-end {
          align-items: flex-end;
}
.items-center {
          align-items: center;
}
.justify-start {
          justify-content: flex-start;
}
.justify-end {
          justify-content: flex-end;
}
.justify-center {
          justify-content: center;
}
.justify-between {
          justify-content: space-between;
}
.justify-around {
          justify-content: space-around;
}
.justify-items-center {
          justify-items: center;
}
.gap-1 {
          gap: 0.25rem;
}
.gap-2 {
          gap: 0.5rem;
}
.gap-3 {
          gap: 0.75rem;
}
.gap-4 {
          gap: 1rem;
}
.self-start {
          align-self: flex-start;
}
.overflow-auto {
          overflow: auto;
}
.overflow-hidden {
          overflow: hidden;
}
.overflow-visible {
          overflow: visible;
}
.overflow-scroll {
          overflow: scroll;
}
.overflow-x-auto {
          overflow-x: auto;
}
.overflow-y-auto {
          overflow-y: auto;
}
.overflow-x-hidden {
          overflow-x: hidden;
}
.overflow-x-visible {
          overflow-x: visible;
}
.overflow-y-scroll {
          overflow-y: scroll;
}
.truncate {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
}
.whitespace-nowrap {
          white-space: nowrap;
}
.break-all {
          word-break: break-all;
}
.rounded-2xl {
          border-radius: 1rem;
}
.rounded-3xl {
          border-radius: 1.5rem;
}
.rounded-full {
          border-radius: 9999px;
}
.rounded-lg {
          border-radius: 0.5rem;
}
.rounded-md {
          border-radius: 0.375rem;
}
.rounded-none {
          border-radius: 0px;
}
.rounded-xl {
          border-radius: 0.75rem;
}
.rounded-t-md {
          border-top-left-radius: 0.375rem;
          border-top-right-radius: 0.375rem;
}
.\!border-0 {
          border-width: 0px !important;
}
.border {
          border-width: 1px;
}
.border-0 {
          border-width: 0px;
}
.border-\[1px\] {
          border-width: 1px;
}
.border-\[2px\] {
          border-width: 2px;
}
.border-b-2 {
          border-bottom-width: 2px;
}
.border-b-\[1px\] {
          border-bottom-width: 1px;
}
.border-t-2 {
          border-top-width: 2px;
}
.border-solid {
          border-style: solid;
}
.border-\[\#ffffff50\] {
          border-color: rgba(255,255,255,0.31373);
}
.border-black {
          border-color: var(--black);
}
.border-error {
          border-color: var(--error);
}
.border-gray-200 {
          --tw-border-opacity: 1;
          border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-gray-300 {
          --tw-border-opacity: 1;
          border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.border-light-error {
          border-color: var(--light-error);
}
.border-secondary {
          border-color: var(--secondary);
}
.border-slate-200 {
          --tw-border-opacity: 1;
          border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}
.\!bg-\[\#00000050\] {
          background-color: rgba(0,0,0,0.31373) !important;
}
.\!bg-main {
          background-color: var(--main) !important;
}
.\!bg-transparent {
          background-color: transparent !important;
}
.bg-\[\#00000010\] {
          background-color: rgba(0,0,0,0.06275);
}
.bg-\[\#00000050\] {
          background-color: rgba(0,0,0,0.31373);
}
.bg-\[\#00000080\] {
          background-color: rgba(0,0,0,0.50196);
}
.bg-\[\#00000099\] {
          background-color: rgba(0,0,0,0.6);
}
.bg-\[\#EDEEF9\] {
          --tw-bg-opacity: 1;
          background-color: rgba(237, 238, 249, var(--tw-bg-opacity));
}
.bg-\[\#ffffff50\] {
          background-color: rgba(255,255,255,0.31373);
}
.bg-black {
          background-color: var(--black);
}
.bg-blue-50 {
          --tw-bg-opacity: 1;
          background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.bg-error {
          background-color: var(--error);
}
.bg-gray-100 {
          --tw-bg-opacity: 1;
          background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
          --tw-bg-opacity: 1;
          background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-main {
          background-color: var(--main);
}
.bg-red-200 {
          --tw-bg-opacity: 1;
          background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.bg-secondary {
          background-color: var(--secondary);
}
.bg-slate-200 {
          --tw-bg-opacity: 1;
          background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}
.bg-slate-50 {
          --tw-bg-opacity: 1;
          background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}
.bg-transparent {
          background-color: transparent;
}
.bg-white {
          --tw-bg-opacity: 1;
          background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-cover {
          background-size: cover;
}
.bg-center {
          background-position: center;
}
.bg-no-repeat {
          background-repeat: no-repeat;
}
.\!p-0 {
          padding: 0px !important;
}
.\!p-\[10px\] {
          padding: 10px !important;
}
.p-0 {
          padding: 0px;
}
.p-1 {
          padding: 0.25rem;
}
.p-2 {
          padding: 0.5rem;
}
.p-3 {
          padding: 0.75rem;
}
.p-4 {
          padding: 1rem;
}
.p-5 {
          padding: 1.25rem;
}
.p-6 {
          padding: 1.5rem;
}
.p-\[20px\] {
          padding: 20px;
}
.\!px-0 {
          padding-left: 0px !important;
          padding-right: 0px !important;
}
.px-0 {
          padding-left: 0px;
          padding-right: 0px;
}
.px-1 {
          padding-left: 0.25rem;
          padding-right: 0.25rem;
}
.px-10 {
          padding-left: 2.5rem;
          padding-right: 2.5rem;
}
.px-16 {
          padding-left: 4rem;
          padding-right: 4rem;
}
.px-2 {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
}
.px-20 {
          padding-left: 5rem;
          padding-right: 5rem;
}
.px-3 {
          padding-left: 0.75rem;
          padding-right: 0.75rem;
}
.px-4 {
          padding-left: 1rem;
          padding-right: 1rem;
}
.px-5 {
          padding-left: 1.25rem;
          padding-right: 1.25rem;
}
.px-6 {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
}
.px-7 {
          padding-left: 1.75rem;
          padding-right: 1.75rem;
}
.px-8 {
          padding-left: 2rem;
          padding-right: 2rem;
}
.px-\[50px\] {
          padding-left: 50px;
          padding-right: 50px;
}
.py-0 {
          padding-top: 0px;
          padding-bottom: 0px;
}
.py-1 {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
}
.py-2 {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
}
.py-3 {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
}
.py-4 {
          padding-top: 1rem;
          padding-bottom: 1rem;
}
.py-5 {
          padding-top: 1.25rem;
          padding-bottom: 1.25rem;
}
.py-6 {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
}
.pb-0 {
          padding-bottom: 0px;
}
.pb-1 {
          padding-bottom: 0.25rem;
}
.pb-16 {
          padding-bottom: 4rem;
}
.pb-3 {
          padding-bottom: 0.75rem;
}
.pb-4 {
          padding-bottom: 1rem;
}
.pb-7 {
          padding-bottom: 1.75rem;
}
.pb-8 {
          padding-bottom: 2rem;
}
.pb-\[100px\] {
          padding-bottom: 100px;
}
.pl-2 {
          padding-left: 0.5rem;
}
.pl-3 {
          padding-left: 0.75rem;
}
.pr-\[114px\] {
          padding-right: 114px;
}
.pt-0 {
          padding-top: 0px;
}
.pt-2 {
          padding-top: 0.5rem;
}
.pt-3 {
          padding-top: 0.75rem;
}
.pt-4 {
          padding-top: 1rem;
}
.pt-5 {
          padding-top: 1.25rem;
}
.pt-6 {
          padding-top: 1.5rem;
}
.pt-7 {
          padding-top: 1.75rem;
}
.text-left {
          text-align: left;
}
.text-center {
          text-align: center;
}
[dir="ltr"] .text-start {
          text-align: left;
}
[dir="rtl"] .text-start {
          text-align: right;
}
.align-top {
          vertical-align: top;
}
.align-middle {
          vertical-align: middle;
}
.align-text-bottom {
          vertical-align: text-bottom;
}
.font-sans {
          font-family: ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.text-2xl {
          font-size: 1.5rem;
          line-height: 2rem;
}
.text-3xl {
          font-size: 1.875rem;
          line-height: 2.25rem;
}
.text-4xl {
          font-size: 2.25rem;
          line-height: 2.5rem;
}
.text-5xl {
          font-size: 3rem;
          line-height: 1;
}
.text-\[10px\] {
          font-size: 10px;
}
.text-\[14px\] {
          font-size: 14px;
}
.text-\[15px\] {
          font-size: 15px;
}
.text-\[32px\] {
          font-size: 32px;
}
.text-base {
          font-size: 1rem;
          line-height: 1.5rem;
}
.text-lg {
          font-size: 1.125rem;
          line-height: 1.75rem;
}
.text-sm {
          font-size: 0.875rem;
          line-height: 1.25rem;
}
.text-xl {
          font-size: 1.25rem;
          line-height: 1.75rem;
}
.text-xs {
          font-size: 0.75rem;
          line-height: 1rem;
}
.font-bold {
          font-weight: 700;
}
.font-extralight {
          font-weight: 200;
}
.font-light {
          font-weight: 300;
}
.font-medium {
          font-weight: 500;
}
.font-normal {
          font-weight: 400;
}
.font-semibold {
          font-weight: 600;
}
.uppercase {
          text-transform: uppercase;
}
.leading-3 {
          line-height: .75rem;
}
.leading-5 {
          line-height: 1.25rem;
}
.leading-7 {
          line-height: 1.75rem;
}
.text-\[\#1A1919\] {
          --tw-text-opacity: 1;
          color: rgba(26, 25, 25, var(--tw-text-opacity));
}
.text-\[\#707070CC\] {
          color: rgba(112,112,112,0.8);
}
.text-black {
          color: var(--black);
}
.text-black-text {
          color: var(--black-text);
}
.text-error {
          color: var(--error);
}
.text-gray-300 {
          --tw-text-opacity: 1;
          color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.text-gray-400 {
          --tw-text-opacity: 1;
          color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.text-gray-500 {
          --tw-text-opacity: 1;
          color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-light-text {
          color: var(--light-text);
}
.text-main {
          color: var(--main);
}
.text-secondary {
          color: var(--secondary);
}
.text-slate-500 {
          --tw-text-opacity: 1;
          color: rgba(100, 116, 139, var(--tw-text-opacity));
}
.text-white {
          --tw-text-opacity: 1;
          color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-white\/90 {
          color: rgba(255, 255, 255, 0.9);
}
.underline {
          text-decoration-line: underline;
}
.no-underline {
          text-decoration-line: none;
}
.opacity-20 {
          opacity: 0.2;
}
.shadow-2xl {
          --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
          --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
          box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.shadow-lg {
          --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
          --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
          box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.shadow-md {
          --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
          --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
          box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.shadow-sm {
          --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
          --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
          box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.shadow-xl {
          --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
          --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
          box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}
.filter {
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
          transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
          transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
          transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 150ms;
}
.transition-transform {
          transition-property: transform;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 150ms;
}
.duration-500 {
          transition-duration: 500ms;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
          display: none;
      }
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
.hover\:\!bg-\[\#00000010\]:hover {
          background-color: rgba(0,0,0,0.06275) !important;
}
.hover\:\!bg-\[\#00000050\]:hover {
          background-color: rgba(0,0,0,0.31373) !important;
}
.hover\:\!bg-black:hover {
          background-color: var(--black) !important;
}
.hover\:\!bg-error:hover {
          background-color: var(--error) !important;
}
.hover\:\!bg-main:hover {
          background-color: var(--main) !important;
}
.hover\:\!bg-secondary:hover {
          background-color: var(--secondary) !important;
}
.hover\:\!bg-transparent:hover {
          background-color: transparent !important;
}
.hover\:\!bg-white:hover {
          --tw-bg-opacity: 1 !important;
          background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}
.hover\:bg-\[\#2b212150\]:hover {
          background-color: rgba(43,33,33,0.31373);
}
.hover\:bg-error:hover {
          background-color: var(--error);
}
.hover\:bg-slate-100:hover {
          --tw-bg-opacity: 1;
          background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}
.hover\:bg-transparent:hover {
          background-color: transparent;
}
.hover\:\!text-black:hover {
          color: var(--black) !important;
}
.hover\:\!text-error:hover {
          color: var(--error) !important;
}
.hover\:\!text-secondary:hover {
          color: var(--secondary) !important;
}
.hover\:\!text-white:hover {
          --tw-text-opacity: 1 !important;
          color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}
.hover\:text-white:hover {
          --tw-text-opacity: 1;
          color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.hover\:no-underline:hover {
          text-decoration-line: none;
}
@media (min-width: 640px) {
          .sm\:h-4 {
                    height: 1rem;
          }
}
@media (min-width: 768px) {
          .md\:absolute {
                    position: absolute;
          }
          .md\:relative {
                    position: relative;
          }
          .md\:bottom-0 {
                    bottom: 0px;
          }
          .md\:left-1\/2 {
                    left: 50%;
          }
          .md\:left-1\/4 {
                    left: 25%;
          }
          .md\:right-6 {
                    right: 1.5rem;
          }
          .md\:top-10 {
                    top: 2.5rem;
          }
          .md\:top-12 {
                    top: 3rem;
          }
          .md\:top-8 {
                    top: 2rem;
          }
          .md\:top-\[150px\] {
                    top: 150px;
          }
          .md\:mx-12 {
                    margin-left: 3rem;
                    margin-right: 3rem;
          }
          .md\:mx-4 {
                    margin-left: 1rem;
                    margin-right: 1rem;
          }
          .md\:mx-5 {
                    margin-left: 1.25rem;
                    margin-right: 1.25rem;
          }
          .md\:my-0 {
                    margin-top: 0px;
                    margin-bottom: 0px;
          }
          .md\:my-10 {
                    margin-top: 2.5rem;
                    margin-bottom: 2.5rem;
          }
          .md\:my-44 {
                    margin-top: 11rem;
                    margin-bottom: 11rem;
          }
          .md\:my-5 {
                    margin-top: 1.25rem;
                    margin-bottom: 1.25rem;
          }
          .md\:mb-0 {
                    margin-bottom: 0px;
          }
          .md\:mb-10 {
                    margin-bottom: 2.5rem;
          }
          .md\:mb-2 {
                    margin-bottom: 0.5rem;
          }
          .md\:mb-20 {
                    margin-bottom: 5rem;
          }
          .md\:mb-7 {
                    margin-bottom: 1.75rem;
          }
          .md\:mb-8 {
                    margin-bottom: 2rem;
          }
          .md\:ml-0 {
                    margin-left: 0px;
          }
          .md\:mr-0 {
                    margin-right: 0px;
          }
          .md\:mr-10 {
                    margin-right: 2.5rem;
          }
          .md\:mr-2 {
                    margin-right: 0.5rem;
          }
          .md\:mr-6 {
                    margin-right: 1.5rem;
          }
          .md\:mt-0 {
                    margin-top: 0px;
          }
          .md\:mt-10 {
                    margin-top: 2.5rem;
          }
          .md\:mt-2 {
                    margin-top: 0.5rem;
          }
          .md\:mt-5 {
                    margin-top: 1.25rem;
          }
          .md\:mt-6 {
                    margin-top: 1.5rem;
          }
          .md\:block {
                    display: block;
          }
          .md\:\!inline {
                    display: inline !important;
          }
          .md\:inline {
                    display: inline;
          }
          .md\:flex {
                    display: flex;
          }
          .md\:grid {
                    display: grid;
          }
          .md\:hidden {
                    display: none;
          }
          .md\:h-4\/5 {
                    height: 80%;
          }
          .md\:h-44 {
                    height: 11rem;
          }
          .md\:h-8 {
                    height: 2rem;
          }
          .md\:h-\[100px\] {
                    height: 100px;
          }
          .md\:h-\[30px\] {
                    height: 30px;
          }
          .md\:h-\[35\%\] {
                    height: 35%;
          }
          .md\:h-\[60px\] {
                    height: 60px;
          }
          .md\:h-full {
                    height: 100%;
          }
          .md\:max-h-60 {
                    max-height: 15rem;
          }
          .md\:\!w-1\/2 {
                    width: 50% !important;
          }
          .md\:\!w-\[140px\] {
                    width: 140px !important;
          }
          .md\:w-1\/2 {
                    width: 50%;
          }
          .md\:w-1\/3 {
                    width: 33.333333%;
          }
          .md\:w-1\/4 {
                    width: 25%;
          }
          .md\:w-2\/5 {
                    width: 40%;
          }
          .md\:w-24 {
                    width: 6rem;
          }
          .md\:w-4\/6 {
                    width: 66.666667%;
          }
          .md\:w-80 {
                    width: 20rem;
          }
          .md\:w-\[100px\] {
                    width: 100px;
          }
          .md\:w-\[1px\] {
                    width: 1px;
          }
          .md\:w-\[280px\] {
                    width: 280px;
          }
          .md\:w-\[394px\] {
                    width: 394px;
          }
          .md\:w-\[400px\] {
                    width: 400px;
          }
          .md\:w-\[500px\] {
                    width: 500px;
          }
          .md\:w-\[87\%\] {
                    width: 87%;
          }
          .md\:w-auto {
                    width: auto;
          }
          .md\:w-full {
                    width: 100%;
          }
          .md\:\!grid-cols-1 {
                    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
          }
          .md\:grid-cols-2 {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
          }
          .md\:flex-row {
                    flex-direction: row;
          }
          .md\:items-start {
                    align-items: flex-start;
          }
          .md\:items-center {
                    align-items: center;
          }
          .md\:justify-start {
                    justify-content: flex-start;
          }
          .md\:justify-end {
                    justify-content: flex-end;
          }
          .md\:justify-center {
                    justify-content: center;
          }
          .md\:justify-between {
                    justify-content: space-between;
          }
          .md\:gap-14 {
                    gap: 3.5rem;
          }
          .md\:gap-7 {
                    gap: 1.75rem;
          }
          .md\:overflow-auto {
                    overflow: auto;
          }
          .md\:overflow-x-auto {
                    overflow-x: auto;
          }
          .md\:overflow-y-auto {
                    overflow-y: auto;
          }
          .md\:overflow-x-visible {
                    overflow-x: visible;
          }
          .md\:rounded-md {
                    border-radius: 0.375rem;
          }
          .md\:border-t-0 {
                    border-top-width: 0px;
          }
          .md\:bg-transparent {
                    background-color: transparent;
          }
          .md\:p-24 {
                    padding: 6rem;
          }
          .md\:p-4 {
                    padding: 1rem;
          }
          .md\:p-6 {
                    padding: 1.5rem;
          }
          .md\:p-\[40px\] {
                    padding: 40px;
          }
          .md\:\!px-\[50px\] {
                    padding-left: 50px !important;
                    padding-right: 50px !important;
          }
          .md\:px-0 {
                    padding-left: 0px;
                    padding-right: 0px;
          }
          .md\:px-10 {
                    padding-left: 2.5rem;
                    padding-right: 2.5rem;
          }
          .md\:px-3 {
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;
          }
          .md\:px-5 {
                    padding-left: 1.25rem;
                    padding-right: 1.25rem;
          }
          .md\:px-8 {
                    padding-left: 2rem;
                    padding-right: 2rem;
          }
          .md\:px-9 {
                    padding-left: 2.25rem;
                    padding-right: 2.25rem;
          }
          .md\:py-16 {
                    padding-top: 4rem;
                    padding-bottom: 4rem;
          }
          .md\:py-4 {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
          }
          .md\:\!pt-2 {
                    padding-top: 0.5rem !important;
          }
          .md\:pb-0 {
                    padding-bottom: 0px;
          }
          .md\:pb-14 {
                    padding-bottom: 3.5rem;
          }
          .md\:pb-2 {
                    padding-bottom: 0.5rem;
          }
          .md\:pr-4 {
                    padding-right: 1rem;
          }
          .md\:pr-\[15px\] {
                    padding-right: 15px;
          }
          .md\:pr-\[315px\] {
                    padding-right: 315px;
          }
          .md\:pt-0 {
                    padding-top: 0px;
          }
          .md\:pt-10 {
                    padding-top: 2.5rem;
          }
          .md\:text-2xl {
                    font-size: 1.5rem;
                    line-height: 2rem;
          }
          .md\:text-3xl {
                    font-size: 1.875rem;
                    line-height: 2.25rem;
          }
          .md\:text-4xl {
                    font-size: 2.25rem;
                    line-height: 2.5rem;
          }
          .md\:text-5xl {
                    font-size: 3rem;
                    line-height: 1;
          }
          .md\:text-\[20px\] {
                    font-size: 20px;
          }
          .md\:text-base {
                    font-size: 1rem;
                    line-height: 1.5rem;
          }
          .md\:text-lg {
                    font-size: 1.125rem;
                    line-height: 1.75rem;
          }
          .md\:text-sm {
                    font-size: 0.875rem;
                    line-height: 1.25rem;
          }
          .md\:text-xl {
                    font-size: 1.25rem;
                    line-height: 1.75rem;
          }
          .md\:leading-7 {
                    line-height: 1.75rem;
          }
          .md\:leading-8 {
                    line-height: 2rem;
          }
          .md\:shadow-xl {
                    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
                    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
                    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
                    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
          }
          .md\:delay-200 {
                    transition-delay: 200ms;
          }
}
@media (min-width: 1024px) {
          .lg\:relative {
                    position: relative;
          }
          .lg\:mx-9 {
                    margin-left: 2.25rem;
                    margin-right: 2.25rem;
          }
          .lg\:mb-8 {
                    margin-bottom: 2rem;
          }
          .lg\:flex {
                    display: flex;
          }
          .lg\:grid {
                    display: grid;
          }
          .lg\:hidden {
                    display: none;
          }
          .lg\:h-14 {
                    height: 3.5rem;
          }
          .lg\:h-\[50\%\] {
                    height: 50%;
          }
          .lg\:w-1\/4 {
                    width: 25%;
          }
          .lg\:w-1\/5 {
                    width: 20%;
          }
          .lg\:w-3\/4 {
                    width: 75%;
          }
          .lg\:w-80 {
                    width: 20rem;
          }
          .lg\:w-fit {
                    width: -moz-fit-content;
                    width: fit-content;
          }
          .lg\:grid-cols-1 {
                    grid-template-columns: repeat(1, minmax(0, 1fr));
          }
          .lg\:grid-cols-2 {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
          }
          .lg\:gap-4 {
                    gap: 1rem;
          }
          .lg\:gap-6 {
                    gap: 1.5rem;
          }
          .lg\:p-0 {
                    padding: 0px;
          }
          .lg\:p-10 {
                    padding: 2.5rem;
          }
          .lg\:px-32 {
                    padding-left: 8rem;
                    padding-right: 8rem;
          }
          .lg\:px-7 {
                    padding-left: 1.75rem;
                    padding-right: 1.75rem;
          }
          .lg\:py-16 {
                    padding-top: 4rem;
                    padding-bottom: 4rem;
          }
          .lg\:pb-0 {
                    padding-bottom: 0px;
          }
          .lg\:pr-\[70px\] {
                    padding-right: 70px;
          }
          .lg\:shadow-none {
                    --tw-shadow: 0 0 rgba(0,0,0,0);
                    --tw-shadow-colored: 0 0 rgba(0,0,0,0);
                    box-shadow: 0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
                    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
          }
}
@media (min-width: 1280px) {
          .xl\:mb-0 {
                    margin-bottom: 0px;
          }
          .xl\:w-1\/4 {
                    width: 25%;
          }
          .xl\:w-1\/6 {
                    width: 16.666667%;
          }
          .xl\:w-\[65\%\] {
                    width: 65%;
          }
          .xl\:\!grid-cols-2 {
                    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
          }
          .xl\:grid-cols-3 {
                    grid-template-columns: repeat(3, minmax(0, 1fr));
          }
          .xl\:grid-cols-4 {
                    grid-template-columns: repeat(4, minmax(0, 1fr));
          }
          .xl\:flex-nowrap {
                    flex-wrap: nowrap;
          }
          .xl\:p-16 {
                    padding: 4rem;
          }
          .xl\:px-32 {
                    padding-left: 8rem;
                    padding-right: 8rem;
          }
          .xl\:px-40 {
                    padding-left: 10rem;
                    padding-right: 10rem;
          }
          .xl\:pl-\[150px\] {
                    padding-left: 150px;
          }
}
@media (min-width: 1536px) {
          .\32xl\:h-24 {
                    height: 6rem;
          }
}
